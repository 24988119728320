import React, { Component } from 'react';
import moment from 'moment';
import Confetti from 'react-confetti'
import api from 'helpers/api';

const prizeTitles = [
  'Congratulations!',
  'Merry Christmas',
  'Woooo!',
  'Happy Scoot\'n!',
  'Merry Scoot\'n!',
  'Flamazing!',
  'Cool!',
];

class Advent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      error: false,
      loading: true,
      opened: false,
      prize: {},
      prizeTitle: prizeTitles[Math.floor(Math.random() * prizeTitles.length)],
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.loadPrize = this.loadPrize.bind(this);
    this.parsePrize = this.parsePrize.bind(this);
    this.handleError = this.handleError.bind(this);

    this.door = this.door.bind(this);
  }

  // ----- Mounting -----

  componentDidMount() {
    document.title = 'Advent Calendar | Flamingo Scooters';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadPrize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // ----- Networking -----

  loadPrize() {
    const uuid = this.props.match.params.uuid;
    if (!uuid) {
      return this.setState({ loading: false, error: `You are not logged in.` });
    }
    return api.post('/promo/advent', { uuid })
      .then(this.parsePrize)
      .catch(this.handleError);
  }

  parsePrize(res) {
    const prizeMap = {
      'MINUTES': { label: 'minutes', multiplier: 1, prefix: '' },
      'UNLOCKS': { label: 'unlocks', multiplier: 1, prefix: '' },
      'CREDIT': { label: 'credit', multiplier: 100, prefix: '$' },
    };
    const prizeData = res.data.data;
    const prize = {
      type: prizeMap[prizeData.type].label,
      value: `${ prizeMap[prizeData.type].prefix }${ parseInt(prizeData.value / prizeMap[prizeData.type].multiplier) }`,
      redeemed: prizeData.redeemed,
    };
    this.setState({ loading: false, prize });
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : e.toString();
    this.setState({ error, loading: false });
  }

  // ----- Handlers -----

  door(e) {
    const box = e.target.closest('.fm-advent-box');
    if (!box) { return }
    const day = box.getAttribute('day');
    const today = moment().format('D');

    // Wrong Day
    if (day !== today) {
      box.classList.add('fm-advent-box-wrong');
      setTimeout(() => {
        box.classList.remove('fm-advent-box-wrong');
      }, 1200);
      return;
    }

    // Correct Day
    if (this.state.opened) { return }
    box.classList.add('fm-advent-box-active');
    this.setState({ confetti: true, opened: true });
    setTimeout(() => {
      this.setState({ modal: true });
      setTimeout(() => this.setState({ confetti: false }), 7500);
    }, 3000);
  }

  // ----- Renders -----

  render() {
    const { loading, error, width, height, prize, confetti, modal, prizeTitle } = this.state;

    // Error render
    if (error) {
      return (
        <div className="fm-advent">
          <div className="fm-advent-error">
            <p className="fm-advent-error-title">{ error }</p>
            <p className="fm-advent-error-text">Try closing this window and tapping the banner in the Flamingo app again.</p>
          </div>
        </div>
      );;
    }

    // Loading error
    if (loading) {
      return (
        <div className="fm-advent">
          <div className="fm-advent-loading">
            <div className="fm-advent-loading-spinner"></div>
          </div>
        </div>
      );;
    }

    // Main render
    return (
      <div className="fm-advent">
        <div className="fm-advent-header">
          <img className="fm-advent-header-wordmark" alt="Flamingo" src="/assets/images/wordmark.svg" />
          <p className="fm-advent-header-title">ADVENT CALENDAR</p>
          <p className="fm-advent-header-description">'Tis the season to ride and glide with Flamingo, and we've got a sleigh-load of prizes coming your way!</p>
          <p className="fm-advent-header-instructions">Open todays door to reveal your prize, but make sure to use it quick, each prize is valid for 24 hours.</p>
        </div>
        <div className="fm-advent-boxes">
          <div className="fm-advent-box fm-advent-box-1 fm-advent-box-sq4 fm-advent-box-gl" onClick={this.door} day={11}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">11</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-2 fm-advent-box-rh2 fm-advent-box-gd" onClick={this.door} day={2}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">2</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-4 fm-advent-box-rh2 fm-advent-box-rd" onClick={this.door} day={6}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">6</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-5 fm-advent-box-rv2 fm-advent-box-w" onClick={this.door} day={16}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">16</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-6 fm-advent-box-sq1 fm-advent-box-rd" onClick={this.door} day={3}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">3</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-7 fm-advent-box-rh3 fm-advent-box-rl" onClick={this.door} day={9}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">9</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-8 fm-advent-box-rv2 fm-advent-box-gd" onClick={this.door} day={14}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">14</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-3 fm-advent-box-rv3 fm-advent-box-rl" onClick={this.door} day={13}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">13</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-9 fm-advent-box-sq1 fm-advent-box-gl" onClick={this.door} day={10}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">10</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-10 fm-advent-box-rv3 fm-advent-box-gd" onClick={this.door} day={23}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">23</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-11 fm-advent-box-sq4 fm-advent-box-w" onClick={this.door} day={8}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">8</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-12 fm-advent-box-rh2 fm-advent-box-gl" onClick={this.door} day={19}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">19</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-13 fm-advent-box-sq1 fm-advent-box-rl" onClick={this.door} day={17}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">17</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-17 fm-advent-box-sq4 fm-advent-box-rd" onClick={this.door} day={25}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">25</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-14 fm-advent-box-rv2 fm-advent-box-gl" onClick={this.door} day={5}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">5</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-15 fm-advent-box-rv3 fm-advent-box-w" onClick={this.door} day={18}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">18</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-16 fm-advent-box-rv2 fm-advent-box-rl" onClick={this.door} day={21}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">21</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-18 fm-advent-box-rh2 fm-advent-box-gd" onClick={this.door} day={15}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">15</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-20 fm-advent-box-rv2 fm-advent-box-gd" onClick={this.door} day={22}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">22</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-21 fm-advent-box-sq1 fm-advent-box-rl" onClick={this.door} day={1}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">1</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-22 fm-advent-box-sq1 fm-advent-box-rd" onClick={this.door} day={4}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">4</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-23 fm-advent-box-sq4 fm-advent-box-rl" onClick={this.door} day={24}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">24</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-19 fm-advent-box-rv3 fm-advent-box-gl" onClick={this.door} day={7}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">7</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-f fm-advent-box-sq1"></div>
          <div className="fm-advent-box fm-advent-box-24 fm-advent-box-rh3 fm-advent-box-gl" onClick={this.door} day={20}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">20</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
          <div className="fm-advent-box fm-advent-box-25 fm-advent-box-rh2 fm-advent-box-w" onClick={this.door} day={12}><div className="fm-advent-box-inner"><p className="fm-advent-box-text">12</p></div><div className="fm-advent-box-inside"><p className="fm-advent-box-value">{ prize.value }</p><p className="fm-advent-box-type">{ prize.type }</p></div></div>
        </div>
        <p className="fm-advent-terms">1 prize per person each calendar day from 1 December 2024 through to 25 December 2024. Only available in Auckland and Dunedin. $1 unlocking fee still applies for free minute vouchers. Minute, unlock and credit vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.</p>
        { confetti && <div className="fm-advent-confetti"><Confetti width={width} height={height} numberOfPieces={100} colors={['#d81759', '#ff4887']} confettiSource={{ x: 0, y: 0, h: 0, w: width }} /></div> }
        {
          modal &&
          <div className="fm-advent-modal">
            <div className="fm-advent-modal-inner">
              <p className="fm-advent-modal-title">{ prizeTitle }</p>
              <p className="fm-advent-modal-prize">You won <b>{ prize.value } { prize.type }</b> today!</p>
              <p className="fm-advent-modal-description">Your prize will automatically be applied to your Flamingo account. Remember to get in quick, you have 24 hours to use your prize.</p>
              <p className="fm-advent-modal-description"><b>Come back tomorrow for another prize!</b></p>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Advent;
